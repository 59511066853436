<template>
  <div>
    <MaterialPreview
      :style="{ visibility: pageInited ? 'visible' : 'hidden' }"
      :materialTitle="courseTitle"
      :materialIntro="courseIntro"
      :materialCover="courseCover"
      :skillArr="skillArr"
      :contentArr="contentArr"
      :examList="examList"
    />
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  import MaterialPreview from '@/components/MaterialPreview/MaterialPreview.vue';

  const pageInited = ref(false);
  const courseTitle = ref('');
  const courseIntro = ref('');
  const courseCover = ref('');
  const skillArr = ref([]);
  const contentArr = ref([]);
  const examList = ref([]);

  function initData() {
    const dataJSON = window.localStorage.getItem('a-teaching-system-preview');
    if (!dataJSON) {
      return;
    }
    const dataObj = JSON.parse(dataJSON);
    const { base_name, introduce, image, exam_list, all_skill, taskArr } = dataObj;
    courseTitle.value = base_name || '';
    courseIntro.value = introduce || '';
    courseCover.value = image || '';
    skillArr.value = all_skill;
    contentArr.value = taskArr;
    examList.value = exam_list || [];

    pageInited.value = true;
  }
  initData();
</script>